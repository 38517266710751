<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input-number
          title="输入库存"
          :labelCol="6"
          v-model:value="quantity"
        />

      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { } from 'ant-design-vue'
import form from '@/mixins/form'

export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')
    const dishId = ref(0)
    const quantity = ref(0)

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      dishId,
      quantity,
      onClose
    }
  },

  methods: {
    open (title, dishId, quantity) {
      this.dishId = dishId
      this.quantity = quantity
      this.title = '设置菜品' + title + '库存'
      this.visible = true
    },

    onSubmitFun () {
      this.loading = true
      this.$emit('ok', {
        dishId: this.dishId,
        stockQuantity: this.quantity
      })
    }
  }
})
</script>

<style lang="less" scoped>
</style>
